@import "../../theme.less";

.error-image {
  height: 300px;
}

.error-text {
  margin-top: 20px;
  font-size: large;
  color: red;
  align-content: center;
}

.common-centered-spin {
  width: 100%;
  padding-top: 30px;
  text-align: center;
}

.common-component-fadein {
  -webkit-animation: fadein 1.5s; /* Safari, Chrome and Opera > 12.1 */
  -moz-animation: fadein 1.5s; /* Firefox < 16 */
  -ms-animation: fadein 1.5s; /* Internet Explorer */
  -o-animation: fadein 1.5s; /* Opera < 12.1 */
  animation: fadein 1.5s;
}

@keyframes fadein {
  from {
    opacity: 0;
  }
  to {
    opacity: 1;
  }
}

/* Firefox < 16 */
@-moz-keyframes fadein {
  from {
    opacity: 0;
  }
  to {
    opacity: 1;
  }
}

/* Safari, Chrome and Opera > 12.1 */
@-webkit-keyframes fadein {
  from {
    opacity: 0;
  }
  to {
    opacity: 1;
  }
}

/* Internet Explorer */
@-ms-keyframes fadein {
  from {
    opacity: 0;
  }
  to {
    opacity: 1;
  }
}

/* Opera < 12.1 */
@-o-keyframes fadein {
  from {
    opacity: 0;
  }
  to {
    opacity: 1;
  }
}

.common-error-details {
  font-family: @font-monospace;
  line-height: normal;
  background: rgba(135, 131, 120, 0.15);
  color: #eb5757;
  border-radius: 3px;
  font-size: 85%;
  padding: 0.2em 0.4em;
}

.code-snippet-small pre {
  line-height: 80%;
  code {
    font-size: 70%;
  }
}

.code-snippet-large pre {
  line-height: 100%;
  padding-top: 15px !important;
  padding-left: 15px !important;
  code {
    font-size: 100%;
  }
}

.code-snippet-wrapper {
  overflow: auto;
  border-radius: @small-border-radius;
  pre {
    overflow: auto;
    border-bottom-left-radius: @small-border-radius;
    border-bottom-right-radius: @small-border-radius;
    font-family: @font-monospace;
    code {
      border-radius: @small-border-radius;
      overflow: auto;
    }
    margin-bottom: 0;
  }
}

.code-snippet-wrapper-bottom {
  border-top-left-radius: @small-border-radius;
  border-top-right-radius: @small-border-radius;
}

.code-snippet-toolbar-top {
  background-color: #1e2a31;
  border-bottom: 1px solid @border-color-split;
  border-top-left-radius: @small-border-radius;
  border-top-right-radius: @small-border-radius;
  padding: 5px 10px 3px;
}

.code-snippet-toolbar-top a {
  color: white;
}

.code-snippet-inline {
  font-family: @font-monospace;
  line-height: normal;
  background: rgba(135, 131, 120, 0.15);
  color: #eb5757;
  border-radius: 3px;
  font-size: 85%;
  padding: 0.2em 0.4em;
}

.action-link {
  display: inline-block;
  font-size: 10px;
  cursor: pointer;

  span {
    border-bottom: 1px dotted;
  }
}

.estimated-progress-bar-op-failed {
  color: red;
}

.estimated-progress-bar {
  height: 10vh;
  min-height: 300px;
}

@bgPrimary: rgb(17, 24, 39);@bgSecondary: rgb(31, 41, 55);@bgComponent: rgb(26, 35, 50);@bgTableHeader: @bgComponent;@splitBorder: #394e5a;@primary: rgb(91, 20, 250);@link: rgb(135, 138, 252);@success: rgb(11, 173, 81);@successHover: #0aa14b;@successActive: #2cc56f;@warning: #ffc021;@error: #e53935;@heading: #c1c9d2;@text: #e5e7eb;@textPale: #ffffff;@secondaryText: #7996a9;@disabled: #415969;@secondaryBorder: #415969;@secondaryBorderHover: #878afc;@secondaryBorderFocus: #5b14fa;@background-color-light: @bgComponent;@primary-background: @bgPrimary;@secondary-background: @bgSecondary;@component-background: @bgComponent;@popover-background: @bgComponent;@link-background: @link;@table-header-bg: @bgTableHeader;@table-row-hover-bg: @bgPrimary;@table-header-sort-active-bg: @bgTableHeader;@table-header-sort-bg: @bgTableHeader;@border-color-split: @splitBorder;@primary-color: @primary;@primary-5: @link;@link-color: @link;@success-color: @success;@warning-color: @warning;@error-color: @error;@heading-color: @heading;@text-color: @text;@text-color-secondary: @secondaryText;@disabled-color: @disabled;@border-color-base: @secondaryBorder;@border-hover-color-base: @secondaryBorderHover;@border-focus-color-base: @secondaryBorderFocus;@divider-color: @secondaryBorder;@alert-success-bg-color: @success-color;@alert-warning-bg-color: @warning-color;@alert-error-bg-color: @error-color;@alert-text-color: @bgPrimary;@alert-message-color: @bgPrimary;@border-radius-base: 6px;@font-size-base: 14px;@box-shadow-base: 0 3px 6px -4px rgba(0, 0, 0, 0.12), 0 6px 16px 0 rgba(0, 0, 0, 0.08),
  0 9px 28px 8px rgba(0, 0, 0, 0.05);@large-border-radius: 12px;@small-border-radius: 6px;@font-main: "system-ui", sans-serif;@font-monospace: "Source Code Pro", monospace;@font-heading: "Nunito Sans", sans-serif;@default-transition-duration: 200ms;@default-transition-delay: 0ms;@default-transition-animation: linear;@default-transiton: @default-transition-duration @default-transition-animation @default-transition-delay;