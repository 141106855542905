.plan {
  @apply flex flex-row justify-between mt-6  space-x-5;
}

.planButton {
  @apply w-56;
}

.planPrice {
  @apply text-xs text-secondaryText text-center;
}
