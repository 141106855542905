.filters {
  align-items: center;
}

.filterOption {
  .icon {
    padding-right: 5px;
    & > svg {
      vertical-align: middle;
      margin-top: -3px;
    }
  }
}
